import { useTranslation } from 'react-i18next';
import { OrganizationJsonLd as NextSeoOrganizationJsonLd } from 'next-seo';

export const OrganizationJsonLd = () => {
  const { t } = useTranslation();

  return (
    <NextSeoOrganizationJsonLd
      type="Organization"
      id="https://myqrcode.com/#organization"
      logo="https://myqrcode.com/myqrcode-logo.svg"
      image="https://myqrcode.com/myqrcode-logo.svg"
      name={t('qr.page.landing.seo.company.name', 'MyQRCode')}
      address={{
        type: 'PostalAddress',
        streetAddress: t('qr.page.landing.seo.company.street', '46, Akademik Stefan Mladenov Str., Office 5'),
        addressLocality: t('qr.page.landing.seo.company.city', 'Sofia'),
        addressRegion: t('qr.page.landing.seo.company.region', 'Sofia'),
        postalCode: t('qr.page.landing.seo.company.postal', '1700'),
        addressCountry: t('qr.page.landing.seo.company.country', 'BG'),
      }}
      url="https://myqrcode.com"
      telephone={t('qr.page.landing.seo.company.telephone', '+1-631-892-9925')}
      email={t('qr.page.landing.seo.company.email', 'support@myqrcode.com')}
    />
  );
};
